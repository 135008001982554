import Chip from "@mui/joy/Chip";
import Link from "@mui/joy/Link";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import Table from "@mui/joy/Table";
import Tooltip from "@mui/joy/Tooltip";
import Typography from "@mui/joy/Typography";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { clientForReactQuery } from "../api";
import { components } from "../api/api";
import { Logs } from "./Logs";
import Button from "@mui/joy/Button";

const defaultLimit = 10;

export interface BuildTableProps {
  responsive: boolean;
  portrait: boolean;
  windowWidth: number;
  windowHeight: number;
  repositoryId: string | undefined;
  ruleId: string | undefined;
  jobId: string | undefined;
  taskId: string | undefined;
}

function Status(props: { status: string }) {
  const statusText =
    (props.status || "").slice(0, 1).toUpperCase() +
    (props.status || "").slice(1);

  let status = <Chip color={"danger"}>{statusText}</Chip>;

  if (props.status === "running") {
    status = <Chip color={"warning"}>{statusText}</Chip>;
  } else if (props.status === "pending") {
    status = <Chip color={"primary"}>{statusText}</Chip>;
  } else if (props.status === "succeeded") {
    status = <Chip color={"success"}>{statusText}</Chip>;
  }

  return status;
}

export function BuildTable(props: BuildTableProps) {
  const [ref, inView] = useInView();

  const [outputId, setOutputId] = useState<string | undefined>(undefined);
  const [showLogModal, setShowLogModal] = useState(false);

  const queryHash = JSON.stringify(props);

  const relevantLimit = defaultLimit;

  const {
    data: infiniteChangesData,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery({
    queryKey: ["changes"],
    queryHash: queryHash,
    queryFn: async ({ pageParam = 0 }) => {
      const res = await clientForReactQuery.GET("/api/changes", {
        params: {
          query: {
            repository_id__eq: props.repositoryId,
            created_at__desc: "",
            depth: 2,
            limit: relevantLimit,
            offset: pageParam,
          },
        },
      });
      return res.data;
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages) => {
      /*
      TODO: this doesn't cater for the fact that we have new data coming in- really we should
      use something like timestamp for the cursor, and even then we should probably split out
      finished executions from processing executions
      */

      if (lastPage!.count === 0) {
        return lastPage!.offset;
      }

      return (lastPage!.offset || 0) + relevantLimit;
    },
  });

  const changesData: {
    objects: components["schemas"]["Change"][];
  } = {
    objects: [],
  };

  const ids = new Set<string>();
  const createdAts: string[] = [];

  infiniteChangesData?.pages.forEach((page) => {
    page?.objects?.forEach((object) => {
      if (!object!.id) {
        return;
      }

      if (ids.has(object.id)) {
        return;
      }

      ids.add(object.id);
      if (object.created_at) {
        createdAts.push(object.created_at);
      }

      changesData!.objects.push(object);
    });
  });

  const truncateStyleProps = props.responsive
    ? {
        maxWidth: "100%",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }
    : {};

  useEffect(() => {
    if (inView && hasNextPage) {
      void fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, inView]);

  return (
    <>
      <Table
        size="sm"
        width="100%"
        sx={{
          width: "100vw",
          th: {
            textAlign: "center",
            p: 0,
            m: 0,
          },
          td: {
            textAlign: "center",
            p: 0,
            m: 0,
            pt: 0.66,
            ...truncateStyleProps,
          },
        }}
        stickyHeader={true}
      >
        <thead>
          <tr>
            <th style={{ width: "200px", ...truncateStyleProps }}>
              {props.responsive ? "W" : "When"}
            </th>
            <th style={{ width: "250px", ...truncateStyleProps }}>
              {props.responsive ? "U" : "URL"}
            </th>
            <th style={{ width: "150px", ...truncateStyleProps }}>
              {props.responsive ? "B" : "Branch"}
            </th>
            <th style={{ width: "325px", ...truncateStyleProps }}>
              {props.responsive ? "C" : "Commit"}
            </th>
            <th style={{ ...truncateStyleProps }}>
              {props.responsive ? "E" : "Executions"}
            </th>
          </tr>
        </thead>
        <tbody>
          {changesData!.objects!.length ? (
            changesData!.objects.map((change, i) => {
              const repository = change!.repository_id_object;

              return (
                <tr key={`execution-table-row-${change.id}`}>
                  <td>{change!.authored_at}</td>
                  <td>
                    <Link
                      href={
                        (repository!.url || "") +
                        "/commit/" +
                        (change!.commit_hash || "")
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      {repository!.url}
                    </Link>
                  </td>
                  <td>
                    <Link
                      href={
                        (repository!.url || "") +
                        "/tree/" +
                        (change!.branch_name || "")
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      {change!.branch_name}
                    </Link>
                  </td>
                  <Tooltip
                    size={"sm"}
                    title={`${change!.authored_by} @ ${change!.authored_at}: ${change!.message}`}
                  >
                    <td>
                      <>
                        <Link
                          href={
                            (repository!.url || "") +
                            "/commit/" +
                            (change!.commit_hash || "")
                          }
                          target="_blank"
                          rel="noreferrer"
                        >
                          {change!.commit_hash}
                        </Link>
                      </>
                    </td>
                  </Tooltip>
                  <td>
                    <Table size="sm" sx={{ p: 0, m: 0 }} borderAxis="y">
                      <tbody>
                        {change?.referenced_by_execution_change_id_objects?.map(
                          (execution) => {
                            return (
                              <tr>
                                <td style={{ width: "200px" }}>
                                  {execution!.job_name}
                                </td>
                                <td style={{ width: "100px" }}>
                                  <Status status={execution!.status!} />
                                </td>
                                <td>
                                  <Table
                                    size="sm"
                                    sx={{ p: 0, m: 0 }}
                                    borderAxis="y"
                                  >
                                    <tbody>
                                      {change?.referenced_by_output_change_id_objects
                                        ?.filter(
                                          (output) =>
                                            output.execution_id ===
                                            execution!.id,
                                        )
                                        .sort((a, b) => {
                                          if (a!.task_index! < b!.task_index!) {
                                            return -1;
                                          } else if (
                                            a!.task_index! > b!.task_index!
                                          ) {
                                            return 1;
                                          } else {
                                            return 0;
                                          }
                                        })
                                        .map((output) => {
                                          return (
                                            <tr>
                                              <td style={{ width: "25px" }}>
                                                {output!.task_name}
                                              </td>
                                              <td style={{ width: "50px" }}>
                                                <Status
                                                  status={output!.status!}
                                                />
                                              </td>
                                              <td style={{ width: "100px" }}>
                                                <Button
                                                  size={"sm"}
                                                  variant="soft"
                                                  color={"primary"}
                                                  sx={{
                                                    fontSize:
                                                      "var(--joy-fontSize-xs, 0.75rem)",
                                                  }}
                                                  onClick={() => {
                                                    setOutputId(output!.id);
                                                    setShowLogModal(true);
                                                  }}
                                                >
                                                  Logs
                                                </Button>
                                              </td>
                                            </tr>
                                          );
                                        })}
                                    </tbody>
                                  </Table>
                                </td>
                              </tr>
                            );
                          },
                        )}
                      </tbody>
                    </Table>
                  </td>
                  {/* <td>{"a"}</td> */}
                  {/* <td style={{ padding: 0, margin: 0 }}> */}
                  {/* <Table size="sm" sx={{ p: 0, m: 0 }} borderAxis="y">
                      <tbody>
                        {outputs.map((output) => {
                          const statusText =
                            (output.status || "").slice(0, 1).toUpperCase() + (output.status || "").slice(1);

                          let status = <Chip color={"danger"}>{statusText}</Chip>;

                          if (output!.status === "running") {
                            status = <Chip color={"warning"}>{statusText}</Chip>;
                          } else if (output!.status === "pending") {
                            status = <Chip color={"primary"}>{statusText}</Chip>;
                          } else if (output!.status === "succeeded") {
                            status = <Chip color={"success"}>{statusText}</Chip>;
                          }

                          return (
                            <tr>
                              <td style={{ width: "33%" }}>{output!.task_name}</td>
                              <td style={{ width: "33%" }}>{status}</td>
                              <td style={{ width: "33%" }}>
                                <Button
                                  size={"sm"}
                                  variant="soft"
                                  color={"primary"}
                                  sx={{
                                    fontSize: "var(--joy-fontSize-xs, 0.75rem)",
                                  }}
                                  onClick={() => {
                                    setOutputId(output!.id);
                                    setShowLogModal(true);
                                  }}
                                >
                                  Logs
                                </Button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table> */}
                  {/* </td> */}
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={5}>
                <Typography color={"neutral"}>
                  (No executions for the selected filters)
                </Typography>
              </td>
            </tr>
          )}
          <tr>
            <td colSpan={5} ref={ref}>
              <Typography color={"neutral"}> </Typography>
            </td>
          </tr>
        </tbody>
      </Table>
      <Modal
        sx={{ p: 0, m: 0 }}
        open={showLogModal}
        onClose={() => {
          setShowLogModal(false);
        }}
      >
        <ModalDialog
          variant="plain"
          size="sm"
          sx={{
            width: "97.5%",
            height: "97.5%",
            p: "1px",
            borderRadius: 1,
            m: 0,
          }}
        >
          <Logs outputId={outputId || ""} />
        </ModalDialog>
      </Modal>
    </>
  );
}
